<template>
  <main>
    <div class="py-5 style_bg" id="Contacts">
      <div class="container py-5">
        <div class="col-sm-6 bg-white mx-auto p-4">
          <h5>AUTHENTIFICATION ESPACE ABONNÉS OU ADMINISTRATEUR</h5>
          <form class="mt-4" @submit.prevent="onSubmit">
            <div class="form-group">
              <input v-model="user.username" type="text" class="form-control form-control-lg style_form_control" name="username" placeholder="Votre email">
            </div>
            <div class="form-group">
              <input type="password" v-model="user.password" class="form-control form-control-lg style_form_control" name="password" placeholder="Votre mot de passe">
            </div>
            <div class="d-flex justify-content-between mb-4">
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3">
                <label class="form-check-label" for="inlineCheckbox3">Se sourvenir</label>
            </div>
            <a class="text-success" href="#">Mot de passe oublié</a>
            </div>
            <div class="mb-3">
              <small>Si vous êtes ici pour la première fois, c'est le moment de <a href="inscrire.html" class="text-success">s'inscrire</a></small>
            </div>
            <div class="form-group">
              <button type="submit" class="btn-block btn btn-lg style_btn btn-outline-secondary px-4">Connexion</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </main>
</template>


<script>
import { mapActions } from 'vuex'
export default {
  name: 'PageLogin',
  data () {
    return {
      user: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    ...mapActions({
      authenticate: 'auth/login'
    }),
    async onSubmit () {
      this.loading = true
      try {
        await this.authenticate(this.user)
        window.location.reload()
        this.loading = false
      }
      catch (error) {
        // })
        if (error) {
          this.notifyError({
            type: 'error.server-error',
            message: `Login ou mot de passe incorrecte.`
          })
          console.error('Authentication error', error)
        }
        this.loading = false
      }
    },
    gotoRegisterPage () {
      this.$router.push({ name: 'register' })
    }
  }
}
</script>
